<template>
  <div id="addGender" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">ALTA DE PUESTO</v-row>
      <v-container fluid class="addForms">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol job">
            <v-row>
              <div class="form-group mb-2">
                <label for="empresa">Empresa</label>
                <select
                  name="empresa"
                  id="empresa"
                  v-model="enterprise"
                  @change="obtenerAreasTrabajos()"
                >
                  <option disabled selected value="0">Selecciona</option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-2">
                <label for="areaRep">Área a la que reporta</label>
                <select name="areaRep" id="areaRep" v-model="area">
                  <option disabled value="0">Selecciona</option>
                  <option
                    v-for="area in areas"
                    :key="area.id"
                    v-bind:value="area.id"
                  >
                    {{ area.nombreArea }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-2">
                <label for="puestoRep">Puesto al que reporta</label>
                <select name="puestoRep" id="puestoRep" v-model="jobr">
                  <option disabled selected>Selecciona</option>
                  <option
                    v-for="job in jobs"
                    :key="job.id"
                    v-bind:value="job.id"
                  >
                    {{ job.nombrePuesto }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-2">
                <label for="posicion">Número de posiciones</label>
                <select name="posicion" id="posicion" v-model="posicion">
                  <option disabled value="0">Selecciona</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                  <option value="60">60</option>
                  <option value="61">61</option>
                  <option value="62">62</option>
                  <option value="63">63</option>
                  <option value="64">64</option>
                  <option value="65">65</option>
                  <option value="66">66</option>
                  <option value="67">67</option>
                  <option value="68">68</option>
                  <option value="69">69</option>
                  <option value="70">70</option>
                  <option value="71">71</option>
                  <option value="72">72</option>
                  <option value="73">73</option>
                  <option value="74">74</option>
                  <option value="75">75</option>
                  <option value="76">76</option>
                  <option value="77">77</option>
                  <option value="78">78</option>
                  <option value="79">79</option>
                  <option value="80">80</option>
                  <option value="81">81</option>
                  <option value="82">82</option>
                  <option value="83">83</option>
                  <option value="84">84</option>
                  <option value="85">85</option>
                  <option value="86">86</option>
                  <option value="87">87</option>
                  <option value="88">88</option>
                  <option value="89">89</option>
                  <option value="90">90</option>
                  <option value="91">91</option>
                  <option value="92">92</option>
                  <option value="93">93</option>
                  <option value="94">94</option>
                  <option value="95">95</option>
                  <option value="96">96</option>
                  <option value="97">97</option>
                  <option value="98">98</option>
                  <option value="99">99</option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2 mb-5">
                <label for="job">Nombre del Puesto</label>
                <input
                  type="text"
                  name="job"
                  id="job"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="job"
                  required
                  maxlength="30"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mt-1">
              <div class="form-group">
                <label for="key">Clave del Puesto</label>
                <input
                  type="text"
                  name="key"
                  id="key"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="key"
                  required
                  maxlength="30"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <router-link to="/job" id="backJob">
                <button class="botonAmarilloDerecho" @click="regresar()">
                  Regresar
                </button>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Tipo Puesto</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar el tipo de puesto?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Tipo de Puesto</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      expiration: false,
      show: false,
      search: "",
      dialog: false,
      job: "",
      key: "",
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      area: 0,
      areas: [],
      jobr: 0,
      jobs: [],
      posicion: 0,
    };
  },
  methods: {
    guardar() {
      this.advice = false;
      /*console.log(this.job);
      console.log(this.key);*/
      this.show = true;
      axios
        .post(
          Server + "/puestos",
          {
            EmpresaId: this.enterprise,
            AreaReportaId: this.area,
            PuestoReportaId: this.jobr,
            NumeroPosiciones: parseInt(this.posicion),
            NombrePuesto: this.job,
            Clave: this.key,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.enterprise = "";
          this.area = 0;
          this.jobr = 0;
          this.posicion = 0;
          this.job = "";
          this.key = "";
          this.respuesta = "El Puesto fue guardado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.area = 0;
            this.jobr = 0;
            this.posicion = 0;
            this.job = "";
            this.key = "";
            this.respuesta = "El Puesto no fue almacenado";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function (gender) {
      this.errors = [];
      if (
        this.enterprise == "" ||
        this.enterprise == " " ||
        this.enterprise == 0
      ) {
        this.errors.push("Se debe especificar la Empresa.");
      }
      if (this.area == "" || this.area == " " || this.area == 0) {
        this.errors.push("Se debe especificar el área a la que reporta.");
      }
      if (this.posicion == "" || this.posicion == " " || this.posicion == 0) {
        this.errors.push("Se debe especificar el número de la posición.");
      }
      if (this.job == "" || this.job == " ") {
        this.errors.push("Se debe especificar el Nombre de Puesto.");
      }
      if (this.key == "" || this.key == " ") {
        this.errors.push("Se debe especificar la clave de Puesto.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    obtenerAreasTrabajos() {
      this.area = 0;
      this.jobr = 0;
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });

      this.jobs = [];
      this.jobs.push({
        id: 0,
        nombrePuesto: "Ninguno",
      });
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    cancel() {
      this.advice = false;
      this.posicion = 0;
      this.area = 0;
      this.jobr = 0;
      this.job = "";
      this.key = "";
      this.enterprise = 0;
    },
    aux() {
      this.confirmation = false;
      this.posicion = 0;
      this.area = 0;
      this.jobr = 0;
      this.job = "";
      this.key = "";
      this.enterprise = 0;
      this.$router.push("/job");
    },
    regresar() {
      this.enterprise = 0;
      this.posicion = 0;
      this.area = 0;
      this.jobr = 0;
      this.job = "";
      this.key = "";
      this.$router.push("/job");
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerAreasTrabajos();
  },
};
</script>